import "./EditLocation.scss";
import MapView from "../../../../../components/MapView";
import Button from "../../../../../components/Button";

import {ReactComponent as BackIcon} from '../../../../../assets/images/back-icon.svg';
import { useState, useEffect } from "react";

const EditLocation = ({location, updateVisit, closeModal, ...props}) => {
    const [userCurrentLocation, setUserCurrentLocation] = useState({});
    const [isMapVisible, setIsMapVisible] = useState(false);
    const [newMarkerLocation, setNewMarkerLocation] = useState(null);

    useEffect(() => {
        setUserCurrentLocation(location);
    }, []);

    useEffect(() => {
        if(userCurrentLocation.latitude){
            setIsMapVisible(true);
        }
    }, [userCurrentLocation]);

    const onLocationChange = (location) => {
        // console.log(location);
        setNewMarkerLocation(location);
    }

    return(
        <div className="geo-location-container">
            <div className="header">
                <div className="back-icon" onClick={() => closeModal()}>
                    <BackIcon />
                </div>
            </div>

            <div className="modal-content">
                <div className="map-container">
                    {isMapVisible && <MapView userLocation={userCurrentLocation} onLocationChange={onLocationChange} />}
                </div>
            </div>

            <div className="footer">
                <div className="btn-container">
                    {newMarkerLocation && <Button value="Update Location" size="medium" color="green" onClick={() => updateVisit(newMarkerLocation)} />}
                </div>
            </div>
        </div>
    )
}

export default EditLocation;