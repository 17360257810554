import { apiPost } from '../hooks/apiCall';

const getReporteesList = (payload) => {
    return apiPost(`${process.env.REACT_APP_API_HOST}/api/user/listreportees`, payload)
}

const updateuserProfile = (payload) => {
    return apiPost(`${process.env.REACT_APP_API_HOST}/api/user/updateuserprofile`, payload)
}

export {
    getReporteesList,
    updateuserProfile
}