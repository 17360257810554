import "./DailyUserHistory.scss";

import {ReactComponent as HomeSolidIcon} from '../../../../assets/images/home-solid-icon.svg';
import {ReactComponent as TopArrowIcon} from '../../../../assets/images/top-arrow.svg';
import {ReactComponent as BottomArrowIcon} from '../../../../assets/images/bottom-arrow.svg';
import {ReactComponent as LocateIcon} from '../../../../assets/images/locate-icon.svg';
import {ReactComponent as BackIcon} from '../../../../assets/images/back-icon.svg';
import {ReactComponent as BackToHomeIcon} from '../../../../assets/images/back2home.svg';
import Button from '../../../../components/Button';
import { useEffect, useState } from "react";

const DailyUserHistory = ({selectedDay, dailyVisits=[], goBack, ...props}) => {

    const [totalKMS, setTotalKMS] = useState(0);

    const visitStatusText = {
        0: "Pending",
        1: "Approved",
        2: "Rejected"
    }

    useEffect(() => {
        if(dailyVisits){
            let total_distance_in_mtrs = dailyVisits.reduce((totaldist, visit, index) => {
                if(visit.visitStatus !== 2){
                    totaldist = totaldist+visit.distancep2p

                    if(index === dailyVisits.length-1){
                        totaldist = totaldist+visit.distancep2home
                    }
                }
                return totaldist;
            }, 0);

            setTotalKMS((total_distance_in_mtrs/1000).toFixed(1));
        }
    }, [dailyVisits]);

    const visitMap = (lat,lng) => {
        window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, "_blank");
    }

    const renderVisits = () => {
        if(dailyVisits){
            return dailyVisits.map((visit, index) => {
                return(
                    <div key={`dist-${visit.createdAt}`}>
                        <div className="distance-from-prev-location" >
                            <TopArrowIcon />
                            <div className="visit-distance">
                                {(visit.distancep2p/1000).toFixed(1)} KM
                            </div>
                            <BottomArrowIcon />
                        </div>
                        <div className="visit-card">
                            <div className="left">
                                <div className="visit-name">Visit {index+1}</div>
                                <div className={`visit-status ${visitStatusText[visit.visitStatus]}`}>{visitStatusText[visit.visitStatus]}</div>
                            </div>
                            <div className="right">
                                <div className="visit-time">{new Date(visit.createdAt).toLocaleString('default', { timeStyle: "short", hour12: true })}</div>
                                <div className="btn-container">
                                    <Button value="Map" icon={<LocateIcon />} size="extrasmall" color="blue" onClick={() => visitMap(JSON.parse(visit.location).lat, JSON.parse(visit.location).lng)} />
                                </div>
                            </div>
                        </div>
                        {(index === dailyVisits.length-1) && (
                            <div className="distance-from-prev-location" >
                                <TopArrowIcon />
                                <div className="distance-to-home">
                                    <HomeSolidIcon />
                                    <span className="back-to-home-distance">{`${(visit.distancep2home/1000).toFixed(1)} KM`}</span>
                                </div>
                                <div className="distance-to-home-text">Back to Home</div>
                            </div>
                        )}
                    </div>
                )
            })
        }
    }

    return(
        <div className="add-visit-container">
            <div className="action-bar">
                <div className="back-btn" onClick={goBack}>
                    <BackIcon />
                </div>
                {selectedDay}
            </div>
            <div className="visits-list">
                <div className="total-lists">
                    <div className="home-card">
                        <HomeSolidIcon />
                        <div className="card-text">Home</div>
                    </div>
                    {renderVisits()}
                </div>
            </div>
            <div className="day-actions">
                <div className={`distance-container day-ended`}>
                    <div className="small-text">TOTAL</div>
                    <div className="medium-text">{totalKMS} KM</div>
                </div>
                <div className="btn-container">
                </div>
            </div>
        </div>
    )
}

export default DailyUserHistory;