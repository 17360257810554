import './Champ.scss';
import { Routes, Route } from "react-router-dom";

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import SelectModule from './SelectModule';
import HomeScreen from './HomeScreen';
import GetLocation from './HomeScreen/AddVisit/GetLocation';
import ApproveVisits from './HomeScreen/ListReportees/ApproveVisits';
import Profile from './Profile';
import History from './History';
import Notification from './Notification';
import ErrorBoundary from '../../ErrorBoundary';
import Error from '../Error';
import { getItem } from '../../util/StorageManagement';
import { useEffect } from 'react';

const Champ = ({tracker}) => {

    useEffect(() => {
        getItem('userinfo').then((userinfo) => {
            tracker.setUserID(userinfo.email);
        })
    }, []);

    return(
        <div className="champ-container">
            <Header />
            <div className='content'>
                <ErrorBoundary>
                    <Routes>
                        <Route path="select-module" element={<SelectModule />} />
                        <Route path="home" element={<HomeScreen />} />
                        <Route path="visit/add" element={<GetLocation />} />
                        <Route path="visits/approve" element={<ApproveVisits />} />
                        <Route path="profile" element={<Profile />} />
                        <Route path="history" element={<History />} />
                        <Route path="notifications" element={<Notification />} />
                        <Route path="*" element={<Error type="Looks like you are lost" />} />
                    </Routes>
                </ErrorBoundary>
            </div>
            
            <Footer />
        </div>
    )
}

export default Champ;