import './Footer.scss';
import { ReactComponent as MenuIcon } from '../../assets/images/menu-icon.svg';
import { ReactComponent as HomeIcon } from '../../assets/images/home-icon.svg';
import { ReactComponent as HistoryIcon } from '../../assets/images/history-icon.svg';
import { ReactComponent as ProfileIcon } from '../../assets/images/profile-icon.svg';
import { NavLink } from "react-router-dom";
import { useState, useEffect, useContext } from 'react';
import { getItem } from '../../util/StorageManagement';
import { ActiveModuleContext } from '../../context/activemodule-context';

const Footer = () => {
    const activeModule = useContext(ActiveModuleContext);

    return(
        <div className='footer-container'>
            <NavLink to="/app/select-module">
                <div className='action-icon action-menu'>
                    <MenuIcon />
                </div>
            </NavLink>
            
            {activeModule.activeModule ? (
                <NavLink to="/app/home">
                    <div className='action-icon action-home'>
                        <HomeIcon />
                    </div>
                </NavLink>
            ) : (
                <div className='action-icon action-home'>
                    <HomeIcon />
                </div>
            )}

            
            

            <NavLink to="/app/history">
                <div className='action-icon action-history'>
                    <HistoryIcon />
                </div>
            </NavLink>
            

            <NavLink to="/app/profile">
                <div className='action-icon action-profile'>
                    <ProfileIcon />
                </div>
            </NavLink>
            
        </div>
    )
}

export default Footer;