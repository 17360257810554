import "./Notification.scss";
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useContext } from "react";
import { getNotifications } from "../../../connectivity/notificationConnect";
import { useNavigate } from "react-router-dom";
import {SelectedDateContext} from "../../../context/date-context";
import { setItem } from "../../../util/StorageManagement";
import { ActiveModuleContext } from "../../../context/activemodule-context";

const Notification = () => {
    const navigate = useNavigate();
    const selected_date = useContext(SelectedDateContext);
    const activeModule = useContext(ActiveModuleContext);
    let {isLoading, error, data:notifications, refetch: fetchNotifications}  =  useQuery(['notifications'], () => getNotifications(), {refetchOnWindowFocus: false, enabled: false});

    const notificationEventType = {
        1: 'visitRejected',
        2: 'visitLocationUpdated',
        3: 'visitsApproved'
    }

    useEffect(() => {
        fetchNotifications();
    },[]);

    const goToNotifiedMessage = (dayEpoch) => {
        selected_date.setSelectedDate(new Date(dayEpoch));
        setItem("activeModule", 'addVisit').then((val) => {
            activeModule.setActiveModule('addVisit');
            navigate(`../home`);
            
        }).catch((err) => {
            console.log("Err", err);
        })
    }

    if (isLoading) return 'Loading...'
    if (error) return 'An error has occurred: ' + error.message

    const renderNotifications = () => {
        return notifications.map((notification) => {
            return (
                <div className={`list ${notificationEventType[notification.eventType]}`} key={notification.id} onClick={() => {goToNotifiedMessage(notification.eventDayEpoch)}}>
                    <div className="notification-text">
                        {notification.message}
                    </div>
                </div>
            )
        })
    }

    return(
        <div className="notification-container">
            <div className='header'>Notifications</div>
            <div className='notifications-list'>
                {renderNotifications()}
                

                {/* <div className='list approved'>
                    <div className="notification-text">
                        Manager has Approved today’ s visit.  
                    </div>
                </div> */}

            </div>
        </div>
    )
}

export default Notification;