import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../modules/Loader";
import { getItem } from "./StorageManagement";

const AuthenticatedModule = ({children}) => {

    const [isAuthenticating, setIsAuthenticating] = useState(true);
    let navigate = useNavigate();

    useEffect(() => {
        checkAuthentication().then((token) => {
            setIsAuthenticating(false);
        })
    },[]);

    const checkAuthentication = () => {
        let authPromise = new Promise(async (resolve, reject) => {
            let accesstoken = await getItem("accesstoken");
            
            if(accesstoken) {
                resolve(accesstoken);
            } else {
                navigate(`/auth/login`);
            }
        });

        return authPromise;
        
    }

    return (
        <>
            {isAuthenticating ? (<Loader />) : children}
        </>
    )
}

export default AuthenticatedModule;