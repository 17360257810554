import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import InstallModal from './components/InstallModal';

import AuthenticatedModule from './util/AuthenticatedModule';
import Authentication from './modules/Authentication';
import Champ from './modules/Champ';
import Error from './modules/Error';
import { SelectedDateContext } from './context/date-context';
import { ActiveModuleContext } from './context/activemodule-context';
import { getItem } from './util/StorageManagement';
import Tracker from '@openreplay/tracker';

const queryClient = new QueryClient();
let deferredPrompt;  

const tracker = new Tracker({
  projectKey: "yLcduc1HkwWCV32KFi1m",
  ingestPoint: "https://openreplay.intellicar.in/ingest",
});

function App() {

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [activeModule, setActiveModule] = useState(null);
  const [installable, setInstallable] = useState(false);

  useEffect(() => {
    getItem('activeModule').then((val) => {
      if(val){
          setActiveModule(val || null);
      }
    });

    beforeInstallPrompt();
    tracker.start();
  }, []);

  const beforeInstallPrompt = () => {
    
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      setInstallable(true);
    });

    window.addEventListener('appinstalled', () => {
      // Log install to analytics
      console.log('INSTALL: Success');
    });
  }

  const handleInstallClick = (e) => {
    // Hide the app provided install promotion
    setInstallable(false);
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
    });
  };

  return (
    <div className="App">
      <ActiveModuleContext.Provider value={{activeModule: activeModule, setActiveModule: setActiveModule}}>
        <SelectedDateContext.Provider value={{selectedDate: selectedDate, setSelectedDate: setSelectedDate}}>
          <QueryClientProvider client={queryClient}>
            <Routes>
              <Route path="/"  element={<Navigate replace to="app/select-module" />} />
              <Route path="app/*" element={
                <AuthenticatedModule>
                  <Champ tracker={tracker} />
                </AuthenticatedModule>
              } />
              <Route path="auth/*" element={<Authentication />} />
              <Route path="*" element={<Error type="Looks like you are lost" />} />
            </Routes>
            {/* <ReactQueryDevtools initialIsOpen={false} position="top-left" /> */}
          </QueryClientProvider>
        </SelectedDateContext.Provider>
        {installable && <InstallModal onInstall={handleInstallClick} />}
      </ActiveModuleContext.Provider>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
    </div>
  );
}

export default App;
