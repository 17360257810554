import './DayEndedModal.scss';

import { endDay } from '../../../../../connectivity/visitsConnect';

const DayEndedModal = ({endDay, closeModal, ...props}) => {

    const onDayEnd = () => {
        endDay();
        closeModal();
    }

    return(
        <div className="day-ended-modal-container">
            <div className='day-modal-box'>
                <div className='day-modal-box-body'>
                    Are you sure you want to end the day?
                </div>
                <div className='day-modal-box-footer'>
                    <div className='btn' onClick={closeModal}>Cancel</div>
                    <div className='btn' onClick={onDayEnd}>Yes</div>
                </div>
            </div>
        </div>
    )
}

export default DayEndedModal;