import './PersonalProfile.scss';
import avatar from '../../../../assets/images/avatar.png'
import {ReactComponent as LogoutIcon} from '../../../../assets/images/logout.svg';
import Button from "../../../../components/Button";
import { deleteAllItems } from "../../../../util/StorageManagement";

const PersonalProfile = ({userInfo}) => {

    const logoutUser = () => {
        deleteAllItems().then(() => {
            window.location.reload();
        })
    }

    return(
        <>
            <div className="profile-details">
                <div className="profile-avatar">
                    <img src={avatar} alt="profile avatar" />
                </div>
                <div className="profile-name">
                    {userInfo?.displayName}
                </div>
                <div className="profile-email">
                    {userInfo?.email}
                </div>
            </div>

            <div className="profile-info">
                <div className="item-list">
                    <div className="label">Address:</div>
                    {userInfo?.currentAddress && <div className="value">{`Lat: ${JSON.parse(userInfo?.currentAddress).lat} Lng: ${JSON.parse(userInfo?.currentAddress).lng}`}</div>}
                </div>
                <div className="item-list">
                    <div className="label">Job Title:</div>
                    <div className="value">{userInfo?.jobTitle}</div>
                </div>
                <div className="item-list">
                    <div className="label">Reporting Manager:</div>
                    <div className="value">{userInfo?.reportsTo}</div>
                </div>
                <div className="item-list">
                    <div className="label">Contact:</div>
                    <div className="value">{userInfo?.contact}</div>
                </div>
            </div>

            <div className="logout-btn">
                <Button value="Log Out" icon={<LogoutIcon />} size="medium" onClick={logoutUser} />
            </div>
        </>
    )
}

export default PersonalProfile;