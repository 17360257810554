import { set, get, setMany, getMany, del, update, clear, delMany, entries, keys, values } from 'idb-keyval';

const getItem = (name) => {
    return get(name);
}

const setItem = (name,value) => {
    return set(name,value);
}

const updateItem = (name,callback) => {
    return update(name,callback);
}

const deleteItem = (name) => {
    return del(name);
}

const setMultipleItems = (items) => {
    // here items should be array of array
    return setMany(items);
}

const getMultipleItems = (items) => {
    // here items should be array of items and response is in array of values
    return getMany(items);
}

const deleteMultipleItems = (items) => {
    // here items should be array of items
    return delMany(items);
}

const getAllItems = () => {
    // here response is array of array
    return entries();
}

const deleteAllItems = (name) => {
    return clear();
}

const getAllItemKeys = () => {
    return keys();
}

const getAllItemValues = () => {
    return values();
}

export {
    setItem,
    getItem,
    deleteItem,
    updateItem,
    setMultipleItems,
    getMultipleItems,
    deleteMultipleItems,
    getAllItems,
    deleteAllItems,
    getAllItemKeys,
    getAllItemValues
};