import { useState, useContext } from 'react';
import "./ListReportees.scss";
import Button from '../../../../components/Button';
import { useQuery } from '@tanstack/react-query';
import { getReporteesList, updateuserProfile } from '../../../../connectivity/usersConnect';
import avatar from '../../../../assets/images/avatar.png'
import {ReactComponent as ExclamationIcon} from '../../../../assets/images/exclamation-icon.svg';
import { toast } from 'react-toastify';

const ListReportees = () => {
    let {isLoading, error, data:reporteesList}  =  useQuery(['reporteesList'], () => getReporteesList({}), {refetchOnWindowFocus: false});
    const [isUpdating, setIsUpdating] = useState(false);
    const [selectedReportee, setSelectedReportee] = useState(null);

    const updateUser = (userKekaId) => {
        setSelectedReportee(userKekaId);
        setIsUpdating(true);
        let payload = {
            kekaId: userKekaId
        }
        updateuserProfile(payload).then((res) => {
            toast(`User profile updated successfully`, {type: 'success'});
            setIsUpdating(false);
            setSelectedReportee(null);
        })
    }

    const handleUserCardClick = (user) => {
        if(!user.currentAddress){
            toast(`Please save home (lattitude, longitude) in user profile in Keka and click on Update.`, {type: 'error'});
        }
    }

    if (isLoading) return 'Loading...'
    if (error) return 'An error has occurred: ' + error.message

    const renderUsers = () => {
        return reporteesList.map((user) => {
            return(
                <div className='list' key={user.kekaId} onClick={() => {handleUserCardClick(user)}} >
                    <div className='avatar'>
                        <img src={avatar} alt="profile avatar" />
                    </div>
                    <div className="user-details">
                        <div className="name">
                            <span>{user.displayName}</span>
                            {!user.currentAddress ? <ExclamationIcon title="No address available" /> : null}
                        </div>
                        <div className="email">{user.email}</div>
                    </div>
                    <div className="btn-container">
                        <Button value="Update" color="blue" size="small" onClick={() => updateUser(user.kekaId)} disabled={isUpdating && selectedReportee === user.kekaId} />
                    </div>
                </div>
            )
        })
    }

    return(
        <div className="user-reportees-container">
            <div className='users-list'>
                {renderUsers()}
            </div>
        </div>
    )
}

export default ListReportees;