import { apiPost } from '../hooks/apiCall';

const getAddressFromLatLng = (payload) => {
    return apiPost(`${process.env.REACT_APP_API_HOST}/api/meta/get_address_from_latlng`, payload)
}

const fetchClientList = (payload) => {
    return apiPost(`${process.env.REACT_APP_API_HOST}/api/meta/fetch_client_list`, payload);

}

export {
    getAddressFromLatLng,
    fetchClientList
}