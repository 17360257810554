import "./Profile.scss";
import { useEffect, useState } from "react";
import { getItem } from "../../../util/StorageManagement";
import PersonalProfile from "./PersonalProfile";
import ListReportees from "./ListReportees";


const Profile = () => {

    const [userInfo, setUserInfo] = useState(null);
    const [activeTab, setActiveTab] = useState('personal');

    useEffect(() => {
        getItem('userinfo').then((userinfo) => {
            setUserInfo(userinfo);
        })
    }, []);

    

    const renderTab = () => {
        switch (activeTab) {
            case 'personal':
                if(userInfo && userInfo.email){
                    return (
                        <PersonalProfile userInfo={userInfo} />
                    );
                }
                
            case 'reportees':
                return (
                    <ListReportees />
                );
            default:
                return null;
        }
    }

    return (
        <div className="profile-container">

            <div className="tab-container">
                <div className="tabs">
                    <div className={`tab ${activeTab === 'personal' ? 'active' : ''}`} onClick={() => setActiveTab('personal')}>Personal</div>
                    <div className={`tab ${activeTab === 'reportees' ? 'active' : ''}`} onClick={() => setActiveTab('reportees')}>Direct Reportees</div>
                </div>
                <div className="tab-content">
                    {renderTab()}
                </div>
            </div>

            
        </div>
    )
}

export default Profile;