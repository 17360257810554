import "./HomeScreen.scss";
import { getItem } from "../../../util/StorageManagement";
import { useEffect, useState, useContext } from "react";
import AddVisit from "./AddVisit";
import ListReportees from "./ListReportees";
import Loader from "../../Loader";
import { ActiveModuleContext } from "../../../context/activemodule-context";

const HomeScreen = () => {
    const activeModule = useContext(ActiveModuleContext);

    const renderScreen = () => {
        if(activeModule.activeModule === 'approveVisit'){
            return(
                <ListReportees />
            )
        } else if(activeModule.activeModule === 'addVisit') {
            return(
                <AddVisit />
            )
        } else {
            return(
                <Loader />
            )
        }
    }

    return(
        <div className="home-screen">
            {renderScreen()}
        </div>
    )
}

export default HomeScreen;