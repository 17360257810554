import { useState, useContext } from 'react';
import "./UserReportees.scss";
import { useQuery } from '@tanstack/react-query';
import { getReporteesList } from '../../../../connectivity/usersConnect';
import avatar from '../../../../assets/images/avatar.png'
import {ReactComponent as BackIcon} from '../../../../assets/images/back-icon.svg';
import UserHistory from '../UserHistory';
import { SelectedDateContext } from '../../../../context/date-context';

const UserReportees = () => {
    let {isLoading, error, data:reporteesList}  =  useQuery(['reporteesList'], () => getReporteesList({}), {refetchOnWindowFocus: false});
    const selected_date = useContext(SelectedDateContext);

    const [selectedUser, setSelectedUser] = useState(null);
    const [isUserHistoryVisible, setIsUserHistoryVisible] = useState(false);

    if (isLoading) return 'Loading...'
    if (error) return 'An error has occurred: ' + error.message

    const renderUsers = () => {
        return reporteesList.map((user) => {
            return(
                <div className='list' key={user.kekaId} onClick={() => {setSelectedUser(user); setIsUserHistoryVisible(true)}} >
                    <div className='avatar'>
                        <img src={avatar} alt="profile avatar" />
                    </div>
                    <div className="user-details">
                        <div className="name">{user.displayName}</div>
                        <div className="email">{user.email}</div>
                    </div>
                </div>
            )
        })
    }

    const renderUserHistory = () => {
        return (
            <div className="user-history-content">
                <div className="action-bar">
                    <div className="back-btn" onClick={() =>{setSelectedUser(null); setIsUserHistoryVisible(false)}}>
                        <BackIcon />
                    </div>
                    {selectedUser.displayName}
                </div>
                <UserHistory timestamp={Number(new Date(selected_date.selectedDate))} user={selectedUser.email} />
            </div>
        )
    }

    const renderContent = () => {
        if(isUserHistoryVisible){
            return renderUserHistory()
        } else {
            return renderUsers()
        }
    }

    return(
        <div className="user-reportees-container">
            {/* <div className='header'>Direct Reportees</div> */}
            <div className='users-list'>
                {renderContent()}
            </div>
        </div>
    )
}

export default UserReportees;