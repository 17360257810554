import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
    getItem,
    deleteAllItems
} from "../util/StorageManagement";

const setAuthorizationHeader = async () => {
    let token = await getItem('accesstoken');
    if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
};

// axios.interceptors.response.use(null, async (error) => {
//     if (
//         error.config &&
//         error.response &&
//         error.response.data.err?.requestrefresh
//     ) {
//         deleteAllItem();
//         window.location.reload();
//     }
  
//     return Promise.reject(error);
// });

const errorHandler = (error) => {
    if (error.response) {
        if(error.response?.data?.error?.name === "TokenExpiredError"){
            deleteAllItems().then(() => {
                window.location.reload();
            })
        }
    }
};

const apiPost = async (url, body) => {
    await setAuthorizationHeader();
    return axios
        .post(
           url,
            body
        )
        .then((response) => response.data.data)
        .catch((error) => {
            errorHandler(error);
            if(error.response?.data?.err?.message){
                toast(`Error: ${error.response?.data?.err?.message}`, {type: 'error'});
            } else if(error.response?.data?.msg){
                toast(`Error: ${error.response?.data?.msg}`, {type: 'error'});
            } else {
                toast(`Error: Something went wrong`, {type: 'error'});
            }
            
            
            throw error;
        });
}

export {apiPost};