import { useState, useEffect, useContext } from "react";
import "./History.scss";
import UserHistory from "./UserHistory";
import UserReportees from "./UserReportees";
import { getItem } from "../../../util/StorageManagement";
import { SelectedDateContext } from "../../../context/date-context";

const History = () => {

    const selected_date = useContext(SelectedDateContext);

    const [activeTab, setActiveTab] = useState('personal');
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        getItem('userinfo').then((userinfo) => {
            setUserInfo(userinfo);
        })
    }, []);

    const renderTab = () => {
        switch (activeTab) {
            case 'personal':
                if(userInfo && userInfo.email){
                    return (
                        <UserHistory timestamp={Number(new Date(selected_date.selectedDate))} user={userInfo.email} />
                    );
                }
                
            case 'reportees':
                return (
                    <UserReportees />
                );
            default:
                return null;
        }
    }

    return (
        <div className="history-container">
            <div className="tab-container">
                <div className="tabs">
                    <div className={`tab ${activeTab === 'personal' ? 'active' : ''}`} onClick={() => setActiveTab('personal')}>Personal</div>
                    <div className={`tab ${activeTab === 'reportees' ? 'active' : ''}`} onClick={() => setActiveTab('reportees')}>Direct Reportees</div>
                </div>
                <div className="tab-content">
                    {renderTab()}
                </div>
            </div>
        </div>
    )
}

export default History;