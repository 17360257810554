import { apiPost } from '../hooks/apiCall';

const getDailyVisits = (payload) => {
    return apiPost(`${process.env.REACT_APP_API_HOST}/api/visits/daily`, payload)
}

const getUserMonthlyVisits = (payload) => {
    return apiPost(`${process.env.REACT_APP_API_HOST}/api/visits/user/monthly`, payload)
}

const getPersonalMonthlyHistory = (payload) => {
    return apiPost(`${process.env.REACT_APP_API_HOST}/api/visits/personal/monthly/history`, payload)
}

const getUserDailyVisits = (payload) => {
    return apiPost(`${process.env.REACT_APP_API_HOST}/api/visits/user/daily`, payload)
}

const addVisit = (payload) => {
    return apiPost(`${process.env.REACT_APP_API_HOST}/api/visit/add`, payload)
}

const endDay = (payload) => {
    return apiPost(`${process.env.REACT_APP_API_HOST}/api/visits/endday`, payload)
}

const getReporteesDailyVisits = (payload) => {
    return apiPost(`${process.env.REACT_APP_API_HOST}/api/visits/reportees_daily_visits`, payload)
}

const rejectVisit = (payload) => {
    return apiPost(`${process.env.REACT_APP_API_HOST}/api/visit/reject`, payload)
}

const undoRejectVisit = (payload) => {
    return apiPost(`${process.env.REACT_APP_API_HOST}/api/visit/undo_reject`, payload)
}

const approveVisits = (payload) => {
    return apiPost(`${process.env.REACT_APP_API_HOST}/api/visit/approve`, payload)
}

const editVisitLocation = (payload) => {
    return apiPost(`${process.env.REACT_APP_API_HOST}/api/visit/location/edit`, payload)
}

const deleteLastVisit = (payload) => {
    return apiPost(`${process.env.REACT_APP_API_HOST}/api/visits/delete_last_visit`, payload)
}

export {
    getDailyVisits,
    getUserMonthlyVisits,
    getUserDailyVisits,
    addVisit,
    endDay,
    getReporteesDailyVisits,
    rejectVisit,
    undoRejectVisit,
    approveVisits,
    editVisitLocation,
    deleteLastVisit,
    getPersonalMonthlyHistory
}