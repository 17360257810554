import React from 'react';
import './ErrorBoundary.scss';
import {ReactComponent as ErrorIcon} from '../assets/images/error-icon.svg';
class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
        console.log(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
            <div className="errorfallback-container">
                <ErrorIcon />
                <div className="errorfallback-message">
                    Oops!! Something went wrong
                </div>
            </div>
        )
      }
  
      return this.props.children; 
    }
}

export default ErrorBoundary;