import './Authentication.scss';
import { Routes, Route } from "react-router-dom";

import Login from './Login';

const Authentication = () => {
    return(
        <div className="authentication-container">
            <Routes>
                <Route path="login" element={<Login />} />
            </Routes>
        </div>
    )
}

export default Authentication;