import './CustomDatePicker.scss';

const DatePickerContainer = ({ className, children }) => {
    return(
        <div className="date-picker-container-container">
            <div className="date-picker-container-backdrop"></div>
            <div className='calendar-container'>{children}</div>
        </div>
    )
}

export default DatePickerContainer;