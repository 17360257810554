import { useState, useEffect } from "react";
import "./MapView.scss";

const MapView = ({userLocation, onLocationChange, isDraggable=true, ...props}) => {

    const [mapState, setMapState] = useState(null);

    useEffect(() => {
        initMap().then((map) => {
            setMapState(map);
            plotUserLocation(map);
        });
    },[]);

    const initMap = () => {
        const mapStyles =[
            {
                featureType: "poi",
                elementType: "labels",
                stylers: [
                      { visibility: "off" }
                ]
            }
        ];

        const initMapPromise = new Promise(async (resolve, reject) => {
            let map = await new window.google.maps.Map(document.getElementById("map"), {
                center: { lat: -34.397, lng: 150.644 },
                zoom: 15,
                mapTypeControlOptions: {
                    // mapTypeIds: [window.google.maps.MapTypeId.ROADMAP, window.google.maps.MapTypeId.HYBRID]
                },
                styles: mapStyles,
                disableDefaultUI: true,
                draggable: isDraggable
            });

            resolve(map);
        });

        return initMapPromise;
    }

    const plotUserLocation = (map) => {
        if(map){
            let user_position = {lat: userLocation.latitude, lng: userLocation.longitude}
            map.setCenter(user_position);
            const marker = new window.google.maps.Marker({
                position: user_position,
                map: map
            });
            map.addListener('drag', () => onMapDrag(map, marker));
            map.addListener('dragend', () => onMapDragEnd(map, marker));
        }
    }

    const onMapDrag = (map, marker) => {
        marker.setPosition( map.getCenter());
    }

    const onMapDragEnd = (map, marker) => {
        let latlngobj = {
            latitude: map.getCenter().lat(),
            longitude: map.getCenter().lng()
        }
        onLocationChange(latlngobj);
    }

    return(
        <div className="map-view-container" id="map">
            MapView
        </div>
    )
}

export default MapView;