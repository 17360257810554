import "./CustomDatePicker.scss";
import { useState, useEffect, forwardRef, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerContainer from "./DatePickerContainer";
import { SelectedDateContext } from "../../context/date-context";

import {ReactComponent as PrevYearIcon} from '../../assets/images/prevYear.svg';
import {ReactComponent as NextYearIcon} from '../../assets/images/nextYear.svg';
import {ReactComponent as PrevMonthIcon} from '../../assets/images/prevMonth.svg';
import {ReactComponent as NextMonthIcon} from '../../assets/images/nextMonth.svg';

const CustomDatePicker = ({calendarType, ...props}) => {
    const selected_date = useContext(SelectedDateContext);

    const [selectedDate, setSelectedDate] = useState(selected_date.selectedDate);

    useEffect(() => {
        setSelectedDate(selected_date.selectedDate)
    },[selected_date.selectedDate])

    const DatePickerButton = forwardRef(({ value, onClick }, ref) => (
        <div className="date-picker-button-container" onClick={onClick} ref={ref}>
          {value}
        </div>
    ));

    const getYear = (date) => { 
        return date.getFullYear()
    }

    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

    const getMonth = (date) => { 
        return month[date.getMonth()];
    }

    const handleDateSelect = (date) => {
        selected_date.setSelectedDate(date);
    }

    return(
        <div className="date-picker-container">
            <DatePicker 
                selected={selectedDate}
                calendarContainer={DatePickerContainer}
                withPortal
                customInput={<DatePickerButton />}
                onSelect={handleDateSelect}
                dateFormat={calendarType === 'monthly' ? "MMM-yyyy" : "dd-MMM-yyyy"}
                showMonthYearPicker={calendarType === 'monthly' ? true : false}
                maxDate={new Date()}
                

                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    decreaseYear,
                    increaseYear,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                    prevYearButtonDisabled,
                    nextYearButtonDisabled,
                  }) => (
                    <div className="date-picker-action-container">
                        <div className="date-picker-btn-container">
                            <button onClick={decreaseYear} disabled={prevYearButtonDisabled}>
                                <PrevYearIcon />
                            </button>
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                <PrevMonthIcon />
                            </button>
                        </div>
                        

                        <div className="date-picker-label-container">
                            <span>{getYear(date)}</span>
                            <span>{getMonth(date)}</span>
                        </div>
                        
                        <div className="date-picker-btn-container">
                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                <NextMonthIcon />
                            </button>
                            <button onClick={increaseYear} disabled={nextYearButtonDisabled}>
                                <NextYearIcon />
                            </button>
                        </div>
                        
                    </div>
                  )}
            />
            
        </div>
    )
}

export default CustomDatePicker;