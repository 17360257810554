import { useContext, useEffect } from 'react';
import "./ListReportees.scss";
import { useQuery } from '@tanstack/react-query';
import { getReporteesDailyVisits } from '../../../../connectivity/visitsConnect';
import avatar from '../../../../assets/images/avatar.png';
import { SelectedDateContext } from '../../../../context/date-context';
import { useNavigate } from "react-router-dom";

const ListReportees = () => {
    const navigate = useNavigate();
    const selected_date = useContext(SelectedDateContext);
    let {isLoading, error, data:reporteesDailyList, refetch: fetchReporteesDailyVisits}  =  useQuery(['reporteesDailyList'], () => getReporteesDailyVisits({day: Number(new Date(selected_date.selectedDate))}), {refetchOnWindowFocus: false, enabled: false});

    useEffect(() => {
        fetchReporteesDailyVisits();
    },[selected_date.selectedDate]);

    if (isLoading) return 'Loading...'
    if (error) return 'An error has occurred: ' + error.message

    const renderUsers = () => {

        if(reporteesDailyList.length <= 0){
            return(
                <div>No visits recorded for this day</div>
            )
        }

        return reporteesDailyList.map((dailyReports, index) => {
            return(
                // <div className={`list ${dailyReports.pending_count ? 'pending' : 'verified'}`} key={dailyReports.user_details.kekaId} onClick={() => {setSelectedUserReport(dailyReports); setIsUserHistoryVisible(true)}} >
                <div className={`list ${dailyReports.pending_count ? 'pending' : 'verified'}`} key={dailyReports.user_details.kekaId} onClick={() => {navigate(`../visits/approve`, {state: {selectedUser: dailyReports.user_details}});}} >
                    <div className='avatar'>
                        <img src={avatar} alt="profile avatar" />
                    </div>
                    <div className="user-details">
                        <div className="name">{dailyReports.user_details.displayName}</div>
                        <div className="email">{dailyReports.user_details.email}</div>
                    </div>
                    <div className='user-trip-details'>
                        <div className={`trip-approval-status ${dailyReports.pending_count ? 'pending' : dailyReports.rejected_count ? 'rejected' : 'approved'}`}>
                            {dailyReports.pending_count ? 'pending' : dailyReports.rejected_count ? 'rejected' : 'approved'}
                        </div>
                        <div className='trip-total-distance'>
                            {((dailyReports.total_distance+dailyReports.back_to_home_distance)/1000).toFixed(1)} KM
                        </div>
                    </div>
                </div>
            )
        })
    }

    return(
        <>
            <div className="list-reportees-container">
                <div className='header'>Approve Visits</div>
                <div className='users-list'>
                    {renderUsers()}
                </div>
            </div>
        </>
        
    )
}

export default ListReportees;