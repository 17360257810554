import Button from '../../../components/Button';
import './Login.scss';
import { ReactComponent as LogoBgIcon } from '../../../assets/images/logo-bg.svg';
import { ReactComponent as GoogleIcon } from '../../../assets/images/google-icon.svg';
import { useEffect, useState } from 'react';
import {loginUser} from '../../../connectivity/authConnect';
import { setMultipleItems } from '../../../util/StorageManagement';
import { useNavigate } from "react-router-dom";

const Login = () => {
    let navigate = useNavigate();

    useEffect(() => {
        initiateGoogleLogin();
    },[]);


    const initiateGoogleLogin = () => {
        if(window.onGoogleLibraryLoad){
            window.onGoogleLibraryLoad();
        } else {
            window.onGoogleLibraryLoad = () => {
                window.google.accounts.id.disableAutoSelect();
                window.google.accounts.id.initialize({
                    client_id: '201949952361-meqe4cd5j5v36grspdrv205480egdmk8.apps.googleusercontent.com',
                    callback: handleCredentialResponse
                });
                window.google.accounts.id.prompt();
                let targetElement = document.getElementById("g_id_onload");
                window.google.accounts.id.renderButton(targetElement,{
                    type: 'standard',
                    theme: 'outline',
                    size: 'large',
                    text: 'signin_with',
                    shape: 'square',
                    logo_alignment: 'left',
                    width: '300'
                })
            }
            window.onGoogleLibraryLoad();
        }
        
    }

    const handleCredentialResponse = (response) => {
        let payload = {
            credential: response.credential
        }

        loginUser(payload).then((res) => {
            setMultipleItems([["accesstoken", res.token],["userinfo", res.userinfo]]).then((val) => {
                navigate(`../../app/select-module`);
            }).catch((err) => {
                console.log("Err", err);
            })
        })
    }

    return(
        <div className='login-container'>
            <div className='bg'>
                <div className='app-name'>
                    <span>Intellicar</span>
                    <span>Champ</span>
                </div>
                <LogoBgIcon />
            </div>
            <div className='form-container'>
                
                <div className='login-btn' id="g_id_onload">
                    {/* <Button id="loginBtn" value="Login with Google" icon={<GoogleIcon />} onClick={() => window.google.accounts.id.prompt()} /> */}
                </div>
            </div>
            
        </div>
    )
}

export default Login;