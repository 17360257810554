import './SelectModule.scss';

import ModuleCard from './ModuleCard';

const SelectModule = () => {
    return(
        <div className='select-module-container'>
            <ModuleCard type="addVisit" />
            <ModuleCard type="approveVisit" />
        </div>
    )
}

export default SelectModule;