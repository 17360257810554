import "./AddVisit.scss";

import {ReactComponent as HomeSolidIcon} from '../../../../assets/images/home-solid-icon.svg';
import {ReactComponent as TopArrowIcon} from '../../../../assets/images/top-arrow.svg';
import {ReactComponent as BottomArrowIcon} from '../../../../assets/images/bottom-arrow.svg';
import {ReactComponent as LocateIcon} from '../../../../assets/images/locate-icon.svg';
import {ReactComponent as DeleteIcon} from '../../../../assets/images/delete-icon.svg';
import {ReactComponent as BackToHomeIcon} from '../../../../assets/images/back2home.svg';
import Button from '../../../../components/Button';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState, useContext } from "react";
import { getDailyVisits, endDay, deleteLastVisit } from "../../../../connectivity/visitsConnect";
import { getItem } from "../../../../util/StorageManagement";
import { toast } from 'react-toastify';
import { SelectedDateContext } from "../../../../context/date-context";
import { useNavigate } from "react-router-dom";
// import useLongPress from "../../../../hooks/useLongPress";

// import GetLocation from "./GetLocation";
import DayEndedModal from "./DayEndedModal";

const AddVisit = () => {

    const selected_date = useContext(SelectedDateContext);
    const navigate = useNavigate();

    // const defaultLongPressOptions = {
    //     shouldPreventDefault: true,
    //     delay: 500,
    // };

    const [totalKMS, setTotalKMS] = useState(0);
    const [isGetLocationVisible, setIsGetLocationVisible] = useState(false);
    const [isDayEndedModalVisible, setIsDayEndedModalVisible] = useState(false);
    const [dayEnded, setDayEnded] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [isTodaySelected, setIsTodaySelected] = useState(true);

    const queryClient = useQueryClient();

    let {isLoading, error, data:dailyVisits, refetch: fetchDailyVisits}  =  useQuery(['dailyVisits'], () => getDailyVisits({day: Number(new Date(selected_date.selectedDate))}), {refetchOnWindowFocus: false, enabled: false});

    const visitStatusText = {
        0: "Pending",
        1: "Approved",
        2: "Rejected"
    }

    useEffect(() => {
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });
    },[]);

    // const mutateDailyVisitsAfterAddVisit = useMutation(addVisit, {
    //     onSuccess: () => {
    //       // Invalidate and refetch
    //       queryClient.invalidateQueries(['dailyVisits']);
    //       setIsGetLocationVisible(false);
    //     },
    // });

    const mutateDailyVisitsAfterEndDay = useMutation(endDay, {
        onSuccess: () => {
          // Invalidate and refetch
          queryClient.invalidateQueries(['dailyVisits']);
          setIsGetLocationVisible(false);
        },
    });

    useEffect(() => {
        fetchDailyVisits();
        if(new Date(selected_date.selectedDate).toLocaleDateString() === new Date().toLocaleDateString()){
            setIsTodaySelected(true);
        } else {
            setIsTodaySelected(false);
        }
    },[selected_date.selectedDate]);

    useEffect(() => {
        getItem("userinfo").then((user) => {
            setUserInfo(user);
            if(!(user && user.currentAddress && JSON.parse(user.currentAddress).lat)){
                toast(`Error: Home address not present`, {type: 'error'});
            }
        })
    },[]);

    useEffect(() => {
        if(dailyVisits){
            let total_distance_in_mtrs = dailyVisits.reduce((totaldist, visit, index) => {
                if(visit.visitStatus !== 2){
                    totaldist = totaldist+visit.distancep2p

                    if(index === dailyVisits.length-1){
                        totaldist = totaldist+visit.distancep2home
                    }
                }
                return totaldist;
            }, 0);

            setTotalKMS((total_distance_in_mtrs/1000).toFixed(1));

            if(dailyVisits[dailyVisits.length-1]?.visitSeq === 2){
                setDayEnded(true);
            }
        }
    }, [dailyVisits]);

    const visitMap = (e, lat,lng) => {
        e.stopPropagation();
        window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, "_blank");
    }

    const navigateToAddVisit = () => {
        navigate("../visit/add")
    }

    const deletelastVisit = () => {
        let text = "Are you sure you want to delete your last visit?";
        if (window.confirm(text) == true) {

            deleteLastVisit({}).then((res) => {
                fetchDailyVisits();
                toast(`Visit deleted successfully`, {type: 'success'});
            })
        }
    }

    // const onVisitCardLongPress = (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     console.log('longpress is triggered', e);
    // };

    // const onVisitCardClick = () => {
    //     console.log('click is triggered')
    // }

    // const longPressEvent = useLongPress(onVisitCardLongPress, onVisitCardClick, defaultLongPressOptions);

    const renderVisits = () => {
        if(dailyVisits){
            return dailyVisits.map((visit, index) => {
                return(
                    <div key={`dist-${visit.createdAt}`} >
                        <div className="distance-from-prev-location" >
                            <TopArrowIcon />
                            <div className={`visit-distance ${visitStatusText[visit.visitStatus]}`}>
                                {(visit.distancep2p/1000).toFixed(1)} KM
                            </div>
                            <BottomArrowIcon />
                        </div>
                        <div className="visit-card">
                            <div className="left">
                                <div className="visit-name">Visit {index+1}</div>
                                <div className={`visit-status ${visitStatusText[visit.visitStatus]}`}>{visitStatusText[visit.visitStatus]}</div>
                            </div>
                            <div className="right">
                                <div className="visit-time">{new Date(visit.createdAt).toLocaleString('default', { timeStyle: "short", hour12: true })}</div>
                                <div className="btn-box">
                                    
                                    <div className="btn-container">
                                        <Button value="Map" icon={<LocateIcon />} size="extrasmall" color="blue" onClick={(e) => visitMap(e, JSON.parse(visit.location).lat, JSON.parse(visit.location).lng)} />
                                    </div>
                                    {(index === dailyVisits.length-1) && isTodaySelected &&  <div className="btn-container">
                                        <Button value="Delete" icon={<DeleteIcon />} size="extrasmall"  onClick={() => deletelastVisit()} />
                                    </div>}
                                </div>
                            </div>
                        </div>
                        {(index === dailyVisits.length-1) && (
                            <div className="distance-from-prev-location" >
                                <TopArrowIcon />
                                <div className="distance-to-home">
                                    <HomeSolidIcon />
                                    <span className="back-to-home-distance">{`${(visit.distancep2home/1000).toFixed(1)} KM`}</span>
                                </div>
                                <div className="distance-to-home-text">Back to Home</div>
                            </div>
                        )}
                    </div>
                )
            })
        }
    }

    if (isLoading) return 'Loading...'
    if (error) return 'An error has occurred: ' + error.message

    return(
        <div className="add-visit-container">
            <div className="visits-list">
                <div className="total-lists">
                    <div className="home-card">
                        <HomeSolidIcon />
                        <div className="card-text">Home</div>
                    </div>
                    {renderVisits()}
                </div>
            </div>
            <div className="day-actions">
                {userInfo && userInfo.currentAddress && JSON.parse(userInfo.currentAddress).lat ? (
                    <>
                        
                        <div className={`distance-container ${dayEnded ? 'day-ended' : ''}`}>
                            <div className="small-text">TOTAL</div>
                            <div className="medium-text">{totalKMS} KM</div>
                        </div>
                        <div className="btn-container">
                            {!dayEnded && isTodaySelected && <Button value="Add Visit" color="green" size="small" onClick={() => navigateToAddVisit()} />}
                        </div>
                    </>
                ) : (
                    <div className="info-window">
                        Ask your manager to update your home address.
                    </div>
                )}
                
                
            </div>

            {/* {isGetLocationVisible && <GetLocation addVisit={addAVisit} closeModal={() => setIsGetLocationVisible(false)} />} */}

            {isDayEndedModalVisible && <DayEndedModal endDay={() => mutateDailyVisitsAfterEndDay.mutate({})} closeModal={() => setIsDayEndedModalVisible(false)} />}

        </div>
    )
}

export default AddVisit;