import './Header.scss';
import { ReactComponent as LogoXSIcon } from '../../assets/images/logo-xs.svg';
import { ReactComponent as NotificationIcon } from '../../assets/images/notification-icon.svg';
import { NavLink, useLocation } from "react-router-dom";
import CustomDatePicker from '../CustomDatePicker';
import { useEffect, useState } from 'react';

const Header = () => {
    const [calendarType, setCalendarType] = useState('daily');
    const location = useLocation();

    useEffect(() => {
        if(location.pathname === '/app/history'){
            setCalendarType('monthly');
        } else {
            setCalendarType('daily');
        }
    },[location]);

    return(
        <div className='header-container'>
            <div className='logo'>
                <LogoXSIcon />
            </div>
            <div className='actions'>
                <div className='date-select'>
                    <CustomDatePicker calendarType={calendarType} />
                </div>
                <NavLink to="/app/notifications">
                    <div className='notifications'>
                        <NotificationIcon />
                    </div>
                </NavLink>
                
            </div>
        </div>
    )
}

export default Header;