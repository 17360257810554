import "./SelectModule.scss";
import {ReactComponent as LogoS} from '../../../assets/images/logo-s.svg';
import Button from "../../../components/Button";
import { setItem } from "../../../util/StorageManagement";
import { useNavigate } from "react-router-dom";
import { ActiveModuleContext } from "../../../context/activemodule-context";
import { useContext } from "react";

const ModuleCard = ({type, ...props}) => {
    const activeModule = useContext(ActiveModuleContext);
    const navigate = useNavigate();

    const ButtonText = {
        addVisit: "Add Visit",
        approveVisit: "Approve Visit"
    }

    const CardText = {
        addVisit: "Add your visits",
        approveVisit: "Approve the visits"
    }

    const activateModule = (modType) => {
        setItem("activeModule", modType).then((val) => {
            activeModule.setActiveModule(modType);
            navigate(`../home`);
            
        }).catch((err) => {
            console.log("Err", err);
        })
        
    }

    return(
        <div className="module-card">
            <LogoS />
            <div className="card-text">
                {CardText[type]}
            </div>

            <div className="btn-container">
                <Button value={ButtonText[type]} size="medium" color={type==='addVisit' ? 'green' : 'blue'} onClick={() => {activateModule(type)}} />
            </div>

        </div>
    )
}

export default ModuleCard