import './ApproveVisits.scss';
import {ReactComponent as HomeSolidIcon} from '../../../../../assets/images/home-solid-icon.svg'
import {ReactComponent as BackIcon} from '../../../../../assets/images/back-icon.svg';
import {ReactComponent as TopArrowIcon} from '../../../../../assets/images/top-arrow.svg';
import {ReactComponent as BottomArrowIcon} from '../../../../../assets/images/bottom-arrow.svg';
import {ReactComponent as BackToHomeIcon} from '../../../../../assets/images/back2home.svg';
import Button from '../../../../../components/Button';
import { useState, useEffect, useContext } from 'react';
import { rejectVisit, undoRejectVisit, approveVisits, editVisitLocation } from '../../../../../connectivity/visitsConnect';
import EditLocation from '../EditLocation';
import { SelectedDateContext } from '../../../../../context/date-context';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserDailyVisits } from '../../../../../connectivity/visitsConnect';
import { toast } from 'react-toastify';

const ApproveVisits = ({...props}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const selected_date = useContext(SelectedDateContext);
    const [totalKMS, setTotalKMS] = useState(0);
    const [isEditLocationVisible, setIsEditLocationVisible] = useState(false);
    const [selectedVisit, setSelectedVisit] = useState(null);
    const [hasPendingStatus, setHasPendingStatus] = useState(false);
    const [totalVisits, setTotalVisits] = useState([]);

    useEffect(() => {
        fetchDailyVisits();
    },[selected_date.selectedDate]);

    useEffect(() => {
        if(totalVisits){
            let has_pending_status = false
            let total_distance_in_mtrs = totalVisits.reduce((totaldist, visit, index) => {
                if(visit.visitStatus !== 2){
                    totaldist = totaldist+visit.distancep2p
                    if(index === totalVisits.length-1){
                        totaldist = totaldist+visit.distancep2home
                    }
                }

                if(visit.visitStatus === 0){
                    has_pending_status = true;
                }
                
                return totaldist;
            }, 0);

            setHasPendingStatus(has_pending_status);

            setTotalKMS((total_distance_in_mtrs/1000).toFixed(1));
        }
    }, [totalVisits]);

    const fetchDailyVisits = () => {
        let payload = {
            visitor: location.state.selectedUser.email,
            day: Number(new Date(selected_date.selectedDate))
        }
        getUserDailyVisits(payload).then((visits) => {
            setTotalVisits(visits);
        });
    }

    const visitStatusText = {
        0: "Pending",
        1: "Approved",
        2: "Rejected"
    }

    // const visitMap = (lat,lng) => {
    //     window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, "_blank");
    // }

    const onVisitReject = (visit) => {
        let text = "Are you sure you want to reject this visit?";
        if (window.confirm(text) == true) {
            let payload = {
                'visitId': visit.id, 
                'day': visit.createdAt, 
                'visitor': visit.createdBy
            }

            rejectVisit(payload).then((res) => {
                fetchDailyVisits();
                toast(`Visit rejected successfully`, {type: 'success'});
            })
        }
    }

    const onVisitUndoReject = (visit) => {
        let text = "Are you sure you want to undo reject this visit?";
        if (window.confirm(text) == true) {
            let payload = {
                'visitId': visit.id, 
                'day': visit.createdAt, 
                'visitor': visit.createdBy
            }

            undoRejectVisit(payload).then((res) => {
                fetchDailyVisits();
                toast(`Visit reject reversed successfully`, {type: 'success'});
            })
        }
    }

    const onVisitLocationUpdate = (userLocation) => {
        let text = "Are you sure you want to update location of this visit?";
        if (window.confirm(text) == true) {
            let payload = {
                'visitId': selectedVisit.id, 
                'day': selectedVisit.createdAt, 
                'visitor': selectedVisit.createdBy,
                "location": {
                    "lat": userLocation.latitude,
                    "lng": userLocation.longitude
                }
            }
            editVisitLocation(payload).then((res) => {
                setSelectedVisit(null); 
                setIsEditLocationVisible(false);
                fetchDailyVisits();
                toast(`Visit location updated successfully`, {type: 'success'});
            });
        }
        
    }

    const onVisitApprove = () => {
        let text = "Are you sure you want to approve this visit?";
        if (window.confirm(text) == true) {
            let payload = {
                'visitor': location?.state?.selectedUser?.email,
                'day': Number(new Date(selected_date.selectedDate))
            }
    
            approveVisits(payload).then((res) => {
                fetchDailyVisits();
                toast(`Visits approved successfully`, {type: 'success'});
            });
        }
        
    }

    const renderVisits = () => {
        if(totalVisits){
            return totalVisits.map((visit, index) => {
                return(
                    <div key={`dist-${visit.createdAt}`} className={`visitCard ${visit.visitStatus===2 ? 'rejected' : null}`}>
                        <div className="distance-from-prev-location" >
                            <TopArrowIcon />
                            <div className="visit-distance">
                                {(visit.distancep2p/1000).toFixed(1)} KM
                            </div>
                            <BottomArrowIcon />
                        </div>
                        <div className="visit-card">
                            <div className="top">
                                <div className="visit-name">Visit {index+1}</div>
                                <div className="visit-time">{new Date(visit.createdAt).toLocaleString('default', { timeStyle: "short", hour12: true })}</div>
                            </div>
                            <div className="center">
                                {/* {addressArray[index] && addressArray[index][1]} */}
                                {visit.textaddress}
                            </div>
                            <div className="bottom">
                                <div className="btn-container">
                                    <Button value="Edit" size="extrasmall" color="blue" onClick={() => {setSelectedVisit(visit); setIsEditLocationVisible(true)} } />
                                </div>
                                <div className="btn-container">
                                    {visit.visitStatus === 2 ? (<Button value="Undo" size="extrasmall" color="grey" onClick={() => onVisitUndoReject(visit)} />) : (<Button value="Reject" size="extrasmall" color="red" onClick={() => onVisitReject(visit)} />)}
                                </div>
                            </div>
                        </div>
                        {(index === totalVisits.length-1) && (
                            <div className="distance-from-prev-location" >
                                <TopArrowIcon />
                                <div className="distance-to-home">
                                    <HomeSolidIcon />
                                    <span className="back-to-home-distance">{`${(visit.distancep2home/1000).toFixed(1)} KM`}</span>
                                </div>
                                <div className="distance-to-home-text">Back to Home</div>
                            </div>
                        )}
                    </div>
                )
            })
        }
    }

    return(
        <div className="approve-visits-container">
            <div className="visits-list">
                <div className="nav-action">
                    <div className="go-back" onClick={() => navigate("../home")}>
                        <BackIcon />
                    </div>
                    <div className="user-details">
                        {location?.state?.selectedUser?.displayName}
                    </div>
                </div>
                <div className="total-lists">
                    <div className="home-card">
                        <HomeSolidIcon />
                        <div className="card-text">Home</div>
                    </div>
                    {renderVisits()}
                </div>
            </div>

            <div className="day-actions">
                <div className={`distance-container day-ended`}>
                    <div className="small-text">TOTAL</div>
                    <div className="medium-text">{totalKMS} KM</div>
                </div>
                <div className="btn-container">
                    {hasPendingStatus && <Button value="Approve" size="small" color="green" onClick={onVisitApprove} />}
                </div>
                
            </div>
            {isEditLocationVisible && selectedVisit &&  <EditLocation location={{latitude: JSON.parse(selectedVisit.location).lat, longitude: JSON.parse(selectedVisit.location).lng}} updateVisit={onVisitLocationUpdate} closeModal={() => setIsEditLocationVisible(false)} />}
        </div>
    )
}

export default ApproveVisits;