import './Error.scss';
import { ReactComponent as ErrorIcon } from '../../assets/images/error-icon.svg'; 
const Error = ({type, ...props}) => {
    return(
        <div className="errorfallback-container">
            <ErrorIcon />
            <div className="errorfallback-message">
                {type || `Oops!! Something went wrong`}
            </div>
        </div>
    )
}

export default Error;