import "./Button.scss";

const Button = ({value, icon, color, size, ...props}) => {

    const renderIcon = () => {
        if(icon){
            return icon
        }
    }
    return(
        <div className={`button-container ${size} ${color}`}>
            <button 
                {...props}
            >
                {renderIcon()}
                {value}
            </button>
        </div>
    )
}

export default Button;