import "./GetLocation.scss";
import MapView from "../../../../../components/MapView";
import Button from "../../../../../components/Button";
import { addVisit } from "../../../../../connectivity/visitsConnect";

import {ReactComponent as BackIcon} from '../../../../../assets/images/back-icon.svg';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import Select from "react-select";
import { fetchClientList } from "../../../../../connectivity/metaConnect";

const GetLocation = ({...props}) => {
    let geoWatch;
    const navigate = useNavigate();
    const [userCurrentLocation, setUserCurrentLocation] = useState({});
    const [description, setDescription] = useState("");
    const [isMapVisible, setIsMapVisible] = useState(false);
    const [clientOptions, setClientOptions] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    useEffect(() => {
        getUserCurrentLocation();
        getClientList();

        return function cleanup() {
            stopWatch();
        };

    }, []);

    useEffect(() => {
        if(userCurrentLocation.latitude){
            setIsMapVisible(true);
        }
    }, [userCurrentLocation]);

    const getUserCurrentLocation = () => {
        if ( !geoWatch ) {
            if (window.navigator.geolocation && window.navigator.geolocation.watchPosition) {
                geoWatch = window.navigator.geolocation.watchPosition(setUserLocation, positionError, {
                    maximumAge: 0,
                    enableHighAccuracy: true
                });
            } else {
                toast(`Geolocation is not supported by this browser.`, {type: 'error'});
            }
        }
        
    }

    const getClientList = () => {
        fetchClientList().then(res => {
            let updated_client = res.data.map((client) => {
                client.label = client.name;
                client.value = client.name;
                return client;
            });
            console.log("updated_client", res)
            setClientOptions(updated_client);
        })
    }

    const setUserLocation = (position) => {
        setUserCurrentLocation(position.coords);
    }

    const positionError = (error) => {
        switch ( error.code ) { 
            case error.PERMISSION_DENIED: 
                
                toast(`User denied the request for Geolocation.`, {type: 'error'});
                break; 
    
            case error.POSITION_UNAVAILABLE: 

                toast(`Location information is unavailable.`, {type: 'error'});
                break; 
    
            case error.TIMEOUT: 
    
                toast(`The request to get user location timed out.`, {type: 'error'});
                break; 
    
            case error.UNKNOWN_ERROR: 
    
                toast(`An unknown error occurred while getting current location.`, {type: 'error'});
                break; 
        }
    }

    const stopWatch = () => { 
        window.navigator.geolocation.clearWatch( geoWatch ); 
        geoWatch = undefined;
    }

    const addAVisit = (userLocation) => {
        let payload = {
            "location": {
                "lat": userLocation.latitude,
                "lng": userLocation.longitude
            },
            "description": description,
            "clientid": selectedClient.id,
            "clientname": selectedClient.name
        }
        // mutateDailyVisitsAfterAddVisit.mutate(payload);
        addVisit(payload).then((res) => {
            // fetchDailyVisits();
            closeModal();
        })
    }

    const closeModal = () => {
        stopWatch();
        navigate("../home")
    }

    const selectClient = (val) => {
        setSelectedClient(val);
    }

    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // #64B28E
          return {
            ...styles,
            backgroundColor: isDisabled
              ? undefined
              : isSelected
              ? '#64B28E'
              : isFocused
              ? '#64B28E'
              : undefined,
      
            ':active': {
              ...styles[':active'],
              backgroundColor: !isDisabled
                ? isSelected
                  ? '#64B28E'
                  : 'inherit'
                : undefined,
            },
          };
        }
      };

    return(
        <div className="geo-location-container">
            <div className="header">
                <div className="back-icon" onClick={() => closeModal()}>
                    <BackIcon />
                </div>
            </div>

            <div className="modal-content">
                <div className="map-container">
                    {isMapVisible && <MapView userLocation={userCurrentLocation} isDraggable={false} />}
                </div>
            </div>

            <div className="visit-details">
                {/* <div className="form-control">
                    <input type="text" className="input-item" placeholder="Client Name" />
                </div> */}

                <div className="input-control">
                    <Select options={clientOptions} onChange={(val) => {selectClient(val)}} styles={colourStyles} />
                </div>

                <div className="form-control textarea" style={{marginTop: '1rem'}}>
                    <textarea className="input-item" placeholder="Description" value={description} onChange={(e) => {setDescription(e.target.value)}} />
                </div>
            </div>

            <div className="footer">
                <div className="btn-container">
                    {userCurrentLocation.latitude && selectedClient?.id && <Button value="Add Visit" size="medium" color="green" onClick={() => addAVisit(userCurrentLocation)} />}
                </div>
                {/* {userCurrentLocation.latitude && <div className="notice">{`${userCurrentLocation.latitude}, ${userCurrentLocation.longitude}`}</div>} */}
            </div>
        </div>
    )
}

export default GetLocation;