import { useEffect, useState } from 'react';
import moment from 'moment';
import './UserHistory.scss';
import { useQuery } from '@tanstack/react-query';
import { getPersonalMonthlyHistory } from '../../../../connectivity/visitsConnect';
import DailyUserHistory from '../DailyUserHistory';
import Button from '../../../../components/Button';
import {ReactComponent as DownloadIcon } from "../../../../assets/images/download.svg"
import { CSVLink, CSVDownload } from "react-csv";

const UserHistory = ({timestamp, user}) => {

    let {isLoading, error, data:personalMonthlyHistory, refetch: fetchPersonalMonthlyHistory}  =  useQuery(['personalMonthlyHistory'], () => getPersonalMonthlyHistory({month: timestamp, user: user}), {refetchOnWindowFocus: false, enabled: false});

    const [isDailyHistoryVisible, setIsDailyHistoryVisible] = useState(false);
    const [selectedDayVisits, setSelectedDayVisits] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    const [csvData, setCsvData] = useState([]);
    const [csvHeader, setCsvHeader] = useState([]);
    const [openDownloadModal, setOpenDownloadModal] = useState(false);
    const [visitCSVData, setVisitCSVData] = useState([]);
    const [visitCSVHeader, setVisitCSVHeader] =  useState([]);

    useEffect(() => {
        fetchPersonalMonthlyHistory();
    },[timestamp]);

    useEffect(() => {
        if(personalMonthlyHistory){
            let header = [
                { label: "Date", key: "date" },
                { label: "Name", key: "displayName" },
                { label: "Email", key: "email" },
                { label: "Total Visits", key: "total_visits" },
                { label: "Total Pending Visits", key: "total_pending_visits" },
                { label: "Total Approved Visits", key: "total_approved_visits" },
                { label: "Total Rejected Visits", key: "total_rejected_visits" },
                { label: "Travelled KM", key: "total_distance" },
                { label: "Amount", key: "amount" }
            ];
            let data = [];
            setCsvHeader(header);
            let dateArray = Object.keys(personalMonthlyHistory);
            for(let date of dateArray){
                let obj = {};
                obj.date = date;
                obj.displayName = personalMonthlyHistory[date].displayName;
                obj.email = personalMonthlyHistory[date].email;
                obj.total_visits = personalMonthlyHistory[date].total_visits;
                obj.total_pending_visits = personalMonthlyHistory[date].total_pending_visits;
                obj.total_approved_visits = personalMonthlyHistory[date].total_approved_visits;
                obj.total_rejected_visits = personalMonthlyHistory[date].total_rejected_visits;
                obj.total_distance = ((personalMonthlyHistory[date].total_distance+personalMonthlyHistory[date].back_to_home_distance)/1000).toFixed(1);
                data.push(obj);
            }
    
            setCsvData(data);
        }

        if (personalMonthlyHistory) {
            let header = [
                { label: "Date", key: "createdAt" },
                { label: "Name", key: "displayName" },
                { label: "Email", key: "createdBy" },
                { label: "Mobile", key: "mobilePhone" },
                { label: "From", key: "from" },
                { label: "To", key: "to" },
                { label: "Description", key: "description" },
                { label: "Total Distance KM", key: "totalDistance" },
            ];
            let data = [];
            setVisitCSVHeader(header);
            let dateArray = Object.keys(personalMonthlyHistory);
            for (let date of dateArray) {
                personalMonthlyHistory[date].visits.map((visit, index)=>{
                    let visitObj ={}
                    let lastEntry = {}
                    visitObj.createdAt = moment(visit.createdAt).format("DD-MM-YYYY h:mm:ss A")
                    visitObj.createdBy = visit.createdBy
                    visitObj.displayName = visit.displayName
                    visitObj.mobilePhone = visit.mobilePhone
                    visitObj.description = visit.description
                    visitObj.totalDistance = (visit.distancep2p/1000).toFixed(1)

                    if (visit.visitSeq == 0){
                        visitObj.from = "Home"
                        visitObj.to = visit.textaddress
                    }else{
                        visitObj.from = personalMonthlyHistory[date].visits[index - 1].textaddress
                        visitObj.to = visit.textaddress
                    }

                    if (personalMonthlyHistory[date].visits.length-1  == index){
                        lastEntry.createdAt = moment(visit.createdAt).format("DD-MM-YYYY h:mm:ss A")
                        lastEntry.createdBy = visit.createdBy
                        lastEntry.displayName = visit.displayName
                        lastEntry.mobilePhone = visit.mobilePhone
                        lastEntry.totalDistance = (visit.distancep2home/1000).toFixed(1)
                        lastEntry.from = visit.textaddress
                        lastEntry.to = "Home"
                    }

                    data.push(visitObj);
                    if (Object.keys(lastEntry).length>0){
                        data.push(lastEntry)
                    }
                })  
            }
            setVisitCSVData(data)

        }     

    },[personalMonthlyHistory])


    // const downloadCSV = () => {
    //     console.log("downloadCSV");
    //     // return <CSVDownload data={csvData} target="_blank" />;

    // }

    const downloadCSVPopup = () =>{
        return(
            <div className='download-csv-modal'>
                <div className='back-modal' onClick={() => setOpenDownloadModal(false)}>
                    <div className='modal-box' >
                        <div className='header'>
                            <div className='title'>Download CSV</div>
                            <div className='close' onClick={()=>setOpenDownloadModal(false)}>X</div>
                        </div>
                        <div className='modal-body'>
                        </div>
                        <div className='modal-footer'>
                            <div className='modal-action'>
                            <CSVLink data={csvData} style={{textDecoration:'none'}} headers={csvHeader} filename={`${new Date(timestamp).toLocaleDateString()}(${user}).csv`} className="download-link">
                                    <Button color="blue" value = "Daily" size="small" />
                                </CSVLink>
                            </div>
                            <div className='modal-action'>
                                <CSVLink data={visitCSVData} style={{ textDecoration: 'none' }} headers={visitCSVHeader} filename={`${new Date(timestamp).toLocaleDateString()}(${user}).csv`} className="download-link">
                                    <Button color="blue" value = "Visit" size="small"  />
                                </CSVLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (isLoading) return 'Loading...'
    if (error) return 'An error has occurred: ' + error.message

    const renderHistory = () => {
        let dates = Object.keys(personalMonthlyHistory).reverse();
        return dates.map((daily) => {
            return(
                <div className={`list ${personalMonthlyHistory[daily].total_pending_visits > 0 ? 'pending' : personalMonthlyHistory[daily].total_rejected_counts > 0 ? 'rejected' : 'approved'}`} 
                    key={`visit-${daily.split(" ").join("_")}`}
                    onClick={() => {setIsDailyHistoryVisible(true); setSelectedDay(daily); setSelectedDayVisits(personalMonthlyHistory[daily].visits)}}
                >
                    <div className='date'>{daily}</div>
                    <div className='kms'>{((personalMonthlyHistory[daily].total_distance+personalMonthlyHistory[daily].back_to_home_distance)/1000).toFixed(1)} KM</div>
                </div>
            )
        })
    }

    const renderAccumulatedMonthlyData = () => {
        let datesArray = Object.keys(personalMonthlyHistory);
        let total_month_distance = 0;
        let total_month_visits = 0;

        for(let date of datesArray){
            total_month_distance = total_month_distance + personalMonthlyHistory[date].total_distance + personalMonthlyHistory[date].back_to_home_distance;
            total_month_visits = total_month_visits + personalMonthlyHistory[date].total_visits
        }

        return(
            <>
                <div className="card">
                    <div className="label">Total Travelled</div>
                    <div className="value travelled">{(total_month_distance/1000).toFixed(1)} KM</div>
                </div>

                <div className="card">
                    <div className="label">Total Visits</div>
                    <div className="value visits">{total_month_visits}</div>
                </div>
            </>
        )
    }

    return(
        <div className='user-history-container'>
            {isDailyHistoryVisible && selectedDayVisits ? (
                <DailyUserHistory selectedDay={selectedDay} dailyVisits={selectedDayVisits} goBack={() => {setIsDailyHistoryVisible(false); setSelectedDay(null); setSelectedDayVisits(null)}} />
            ) : (
                <>
                    <div className='accum-data-container'>
                        {renderAccumulatedMonthlyData()}
                    </div>

                    <div className='detailed-history'>
                        <div className='header'>
                            History
                            <div className="btn-container">
                                    <Button value="Download"  size="small" onClick={()=>setOpenDownloadModal(true)} icon={<DownloadIcon />} />
                                
                                {/* <CSVLink data={csvData} headers={csvHeader} filename={`${new Date(timestamp).toLocaleDateString()}(${user}).csv`} className="download-link">
                                    <Button value = "Download" size="small" icon={<DownloadIcon />} />
                                </CSVLink> */}
                            </div>
                        </div>
                        <div className='history-list'>
                            {renderHistory()}
                        </div>
                            {openDownloadModal && downloadCSVPopup()}
                    </div>
                </>
            )}
            
        </div>
    )
}

export default UserHistory;