import { apiPost } from '../hooks/apiCall';

const getVapidPublicKey = (payload) => {
    return apiPost(`${process.env.REACT_APP_API_HOST}/api/pushnotification/vapidPublicKey`, payload)
}

const subscribePushNotification = (payload) => {
    return apiPost(`${process.env.REACT_APP_API_HOST}/api/pushnotification/subscribePushNotification`, payload)
}

const unSubscribePushNotification = (payload) => {
    return apiPost(`${process.env.REACT_APP_API_HOST}/api/pushnotification/unSubscribePushNotification`, payload)
}

export {
    getVapidPublicKey,
    subscribePushNotification,
    unSubscribePushNotification
}